let routes = [
		{
			redirect:{name:"index"},
			component:"layout",
			children:[
				{
					meta:{title:"首页"},
					component:"index/index"
				},
				{
					meta:{title:"后台设置"},
					component:"admin/index"
				},
				{
					meta:{title:"小程序设置"},
					component:"miniprogram/index"
				},
				{
					meta:{title:"账户列表"},
					component:"worker/list"
				},
				{
					meta:{title:"客户列表"},
					component:"user/user/list"
				},
				{
					meta:{title:"评论列表"},
					component:"user/comment/list"
				},
				{
					meta:{title:"毛坯验房服务列表"},
					component:"service/roughInspect/list"
				},
				{
					meta:{title:"毛坯验房服务编辑"},
					component:"service/roughInspect/edit"
				},
				{
					meta:{title:"精装验房服务"},
					component:"service/deluxeInspect/list"
				},
				{
					meta:{title:"精装验房服务编辑"},
					component:"service/deluxeInspect/edit"
				},
				{
					meta:{title:"装修监理服务列表"},
					component:"service/supervision/list"
				},
				{
					meta:{title:"装修监理服务编辑"},
					component:"service/supervision/edit"
				},
				{
					meta:{title:"404错误"},
					component:"error/404"
				},
			]
		},
		{
			meta:{title:"登录页"},
			component:"login/index"
		},
		{
			path:"*",
			redirect:{name:"index"}
		}
]

function getRoutes(){
	createRoutes(routes)
	return routes
}

function createRoutes(arr){
	for (let i = 0; i < arr.length; i++) {
		if(!arr[i].component) return
		let val = editValue(arr[i].component)
		arr[i].name = arr[i].name || val.replace(/\//g,"_")
		arr[i].path = arr[i].path || `/${val}`
		let componentFun = import(`../../view/${arr[i].component}.vue`)
		arr[i].component = ()=>componentFun
		if(arr[i].children && arr[i].children.length >0) createRoutes(arr[i].children)
	}
}

function editValue(str){
	let index = str.lastIndexOf('/')
	let val = str.substring(index+1,str.length)
	if(val === "index") {
		return str.substring(index,-1)
	}
	return str
}

export default getRoutes()