export default {
	state:{
		user:{},
		token:false
	},
	getter:{
		
	},
	mutations:{
		//初始化用户信息
		initUser(state) {
			let user = window.sessionStorage.getItem('user')
			if(user) {
				state.user = JSON.parse(user)
				state.token = window.sessionStorage.getItem('token')
			}
		},
		//登录
		login(state,user) {
			state.user = user
			state.token = user.token
			window.sessionStorage.setItem('user',JSON.stringify(state.user))
			window.sessionStorage.setItem('token',state.token)
		},
		//退出登录
		logout(state) {
			state.user = {}
			state.token = false
			window.sessionStorage.clear()
		}
	},
	actions:{
		
	}
}