import Vue from "vue"
import Router from "vue-router"

import layout from "./view/layout.vue"
import routes from "./common/config/routes.js"

// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(err => err)
}

Vue.use(Router)

let router = new Router({
	routes
})

router.beforeEach((to, from, next) => {
	let token = window.sessionStorage.getItem('token')
	let user = window.sessionStorage.getItem('user')
	if(token) {
		//防止重复登录
		if(to.path === '/login') {
			return next({name:from.name?from.name:'index'})
		}
		//验证权限规则
		if(to.name !== 'error_404') {
			if(user) {
				user = JSON.parse(user)
				if(user.super === 1) {
					return next();
				}
			}
			let rules = window.sessionStorage.getItem('rules')
			rules = rules ? JSON.parse(rules) : []
			let index = rules.findIndex(item=>{
				return item.rule_id > 0 && item.desc === to.name
			})
			console.log(index)
			if(index === -1) {
				Vue.prototype.$message.error('您没权限访问')
				return next({name:from.name ? from.name : 'error_404'})
			}
		}
		next();
	} else {
		if(to.path === '/login') {
			return next();
		}
		Vue.prototype.$message.error('请先登录')
		next({path:'/login'})
	}
})

export default router
