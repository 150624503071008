import Vue from 'vue'
import App from './App.vue'
import './plugins/element.js'
import router from './router.js'
import axios from 'axios'
import VueAxios from 'vue-axios'
import store from './store/index.js'
import Print from './plugins/print.js'
import VueQuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

import {
	Message,
	Loading
} from 'element-ui'

Vue.use(VueAxios, axios)
Vue.use(Print)
Vue.use(VueQuillEditor)

// 添加请求拦截器
axios.interceptors.request.use((config) => {
	// 在发送请求之前做些什么
	let token = window.sessionStorage.getItem('token')
	if (config.token === true) {
		config.headers['token'] = token
	}
	return config;
}, (error) => {
	// 对请求错误做些什么
	return Promise.reject(error);
});

// 添加响应拦截器
axios.interceptors.response.use((response) => {
	// 对响应数据做点什么
	return response;
}, (error) => {
	// 对响应错误做点什么
	if (error.response && error.response.data) {
		Message.error(error.response.data.message);
	}
	return Promise.reject(error);
});

// 注册一个全局自定义指令
Vue.directive('auth', {
  // 当被绑定的元素插入到 DOM 中时……
  inserted(el,binding) {
	let user = window.sessionStorage.getItem('user')
	user = user ? JSON.parse(user) : {}
	if(!user.super) {
		let rules = user.ruleNames ? user.ruleNames : []
		let v = rules.find(item => item === binding.value)
		if(!v) {
			el.parentNode.removeChild(el)
		}
	}
  }
})

//引入全局配置文件
import $conf from './common/config/config.js'
Vue.prototype.$conf = $conf

Vue.config.productionTip = false

new Vue({
	store,
	router,
	render: h => h(App),
}).$mount('#app')
