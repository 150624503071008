export default {
	systemName: "爱屋验房管理系统",
	copyName:'爱屋验房',
	serverUrl:'http://awyfapi.hongyb.com/',
	navBar: {
		activeIndex: '1',
		List: [{
				name: "数据看板",
				subActive: '0',
				subMenu: [{
						name: "首页",
						icon: "el-icon-s-home",
						pathname:'index'
					},
				]
			},
			{
				name: "服务管理",
				subActive: '0',
				subMenu: [
					{
						name: "验房服务列表",
						icon: "el-icon-s-order",
						pathname:'service_inspect_list'
					},
					{
						name: "监理服务列表",
						icon: "el-icon-s-order",
						pathname:'service_supervision_list'
					},
				]
			},
			{
				name: "账户管理",
				subActive: '0',
				subMenu: [
					{
						name: "账户列表",
						icon: "el-icon-s-order",
						pathname:'worker_inspector_list'
					},
				]
			},
			{
				name: "客户管理",
				subActive: '0',
				subMenu: [{
						name: "客户列表",
						icon: "el-icon-s-custom",
						pathname:'user_user_list'
					},
					{
						name: "评论列表",
						icon: "el-icon-s-comment",
						pathname:'user_comment_list'
					},
				]
			},
			{
				name: "设置",
				subActive: '0',
				subMenu: [
					{
						name: "后台设置",
						icon: "el-icon-user-solid",
						pathname:'admin'
					},
					{
						name: "小程序设置",
						icon: "el-icon-user-solid",
						pathname:'miniprogram'
					},
				]
			},
		]
	}
}
