<template>
	<div id="app">
		<router-view></router-view>

	</div>
</template>

<script>
	export default {
		name: 'app',
		components: {

		},
		created() {
			this.$store.commit('initUser')
			this.$store.commit('initMenu')
		}
	}
</script>

<style>
	/* 滚动条美化 */
	::-webkit-scrollbar {
		width: 7px;
		height: 7px;
	}

	/*外层轨道。可以用display:none让其不显示，也可以添加背景图片，颜色改变显示效果*/
	::-webkit-scrollbar-track {
		width: 6px;
		background-color: #FAFAFA;
		-webkit-border-radius: 2em;
		-moz-border-radius: 2em;
		border-radius: 2em;
	}

	/*滚动条的设置*/
	::-webkit-scrollbar-thumb {
		background-color: #E6E6E6;
		background-clip: padding-box;
		min-height: 28px;
		-webkit-border-radius: 2em;
		-moz-border-radius: 2em;
		border-radius: 2em;
	}

	/*滚动条移上去的背景*/
	::-webkit-scrollbar-thumb:hover {
		background-color: #fff;
	}

	/* element表格线对齐 */
	body .el-table th.gutter {
		display: table-cell !important
	}
	
	.color-danger {
		color:#F56C6C !important;
	}
	
</style>
