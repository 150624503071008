<template>
	<div>
		<el-container style="position: absolute;left: 0;right: 0;top: 0;bottom: 0;overflow: hidden;">
			<el-header class="d-flex align-items-center">
				<a class="text-white h5 mb-0">{{$conf.systemName}}</a>
				<el-menu :default-active="navBar.activeIndex" class="ml-auto border-0" mode="horizontal"
					@select="handleSelect" background-color="#545c64" text-color="#fff" active-text-color="#ffd04b">
					<el-menu-item v-for="(item,index) in navBar.List" :key="index" :index="index|numToString">
						{{item.name}}</el-menu-item>
					<el-submenu index="5">
						<template slot="title">
							<el-avatar size="small"
								:src="user.avatar? user.avatar : 'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png'">
							</el-avatar>
							{{user.username}}
						</template>
						<el-menu-item index="5-1">退出</el-menu-item>
					</el-submenu>
				</el-menu>
			</el-header>
			<el-container :class='printcss' style="height: 100%;">
				<el-aside width="200px">
					<el-menu :default-active="subMenuActive" class="h-100" @select="sideSelect">
						<el-menu-item v-for="(item,index) in subMenuList" :key="index" :index="index|numToString">
							<i :class="item.icon"></i>
							<span slot="title">{{item.name}}</span>
						</el-menu-item>
					</el-menu>
				</el-aside>
				<el-main v-loading="loading">
					<el-breadcrumb separator-class="el-icon-arrow-right" v-if="branList.length>0" class="mb-3">
						<el-breadcrumb-item v-for="(item,index) in branList" :key="index" :to="{ path: item.path }">
							{{item.title}}</el-breadcrumb-item>
					</el-breadcrumb>

					<router-view></router-view>

					<el-backtop target=".el-main" :bottom="100">
						<div style="{
					        height: 100%;
					        width: 100%;
					        background-color: #f2f5f6;
					        box-shadow: 0 0 6px rgba(0,0,0, .12);
					        text-align: center;
					        line-height: 40px;
					        color: #1989fa;
					      }">
							UP
						</div>
					</el-backtop>
				</el-main>
			</el-container>
		</el-container>
	</div>
</template>

<script>
	import common from "@/common/mixins/common.js"
	import {
		mapState
	} from 'vuex'
	export default {
		mixins: [common],
		provide(){
			return {
				layout:this
			}
		},
		data() {
			return {
				branList: [],
				loading:false,
				printcss:'nosettlement'
			};
		},
		created() {
			this.getRouterBran()
			this.__initNavBar()
		},
		computed: {
			...mapState({
				user: state => state.user.user,
				navBar:state=>state.menu.navBar
			}),
			subMenuActive: {
				get() {
					let item = this.navBar.List[this.navBar.activeIndex]
					return item ? item.subActive : '0'
				},
				set(val) {
					let item = this.navBar.List[this.navBar.activeIndex]
					if(item) {
						item.subActive = val
					}
				}
			},
			subMenuList() {
				let item = this.navBar.List[this.navBar.activeIndex]
				return item ? item.subMenu : []
			}
		},
		watch: {
			'$route'(to, from) {
				console.log(to)
				if(to.name == 'order_settlement_print'){
					this.printcss = 'settlement'
				}else{
					this.printcss = 'nosettlement'
				}
				localStorage.setItem('navActive', JSON.stringify({
					top: this.navBar.activeIndex,
					left: this.subMenuActive
				}))
				this.getRouterBran()
			}
		},
		methods: {
			__initNavBar() {
				let r = localStorage.getItem('navActive')
				if (r) {
					r = JSON.parse(r)
					this.navBar.activeIndex = r.top
					this.subMenuActive = r.left
				}
			},
			getRouterBran() {
				let b = this.$route.matched.filter(v => v.name)
				let arr = []
				b.forEach((v, k) => {
					if (v.name === 'index' || v.name === 'layout') return
					arr.push({
						name: v.name,
						path: v.path,
						title: v.meta.title
					})
				})
				// if (arr.length > 0) {
				// 	arr.unshift({
				// 		name: 'index',
				// 		path: '/index',
				// 		title: '后台首页'
				// 	})
				// }
				this.branList = arr
			},
			handleSelect(key, keyPath) {
				if (key === '5-1') {
					console.log('loginout')
					this.loginOut()
					return
				}
				this.navBar.activeIndex = key
				this.subMenuActive = '0'
				if (this.subMenuList.length > 0) {
					this.$router.push({
						name: this.subMenuList[this.subMenuActive].pathname
					})
				}
			},
			sideSelect(key, keyPath) {
				this.subMenuActive = key
				this.$router.push({
					name: this.subMenuList[key].pathname
				})
			},
			loginOut() {
				this.axios.post('/admin/admin/logout', {}, {
						token:true
					})
					.then((response)=> {
						this.$message({
							message: '退出成功',
							type: 'success'
						});
						this.$store.commit('logout')
						this.$router.push({
							name: 'login'
						})
					})
					.catch((error)=> {
						this.$store.commit('logout')
						this.$router.push({
							name: 'login'
						})
					});
			}
		}
	}
</script>

<style>
	.el-header {
		background-color: #545c64;
		color: #333;
	}

	.el-aside {
		background-color: #ffffff;
		color: #333;
	}

	.el-main {
		background-color: #F4F7FC;
		color: #333;
		padding-bottom: 90px;
	}
	
	@media print {
		.nosettlement{
			height: 100%;
		}
		
		.settlement{
			height: auto !important;
		}
	}
</style>
