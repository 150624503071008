export default {
	state:{
		navBar: {
			activeIndex: '0',
			List: []
		}
	},
	getter:{
		
	},
	mutations:{
		//初始化菜单
		initMenu(state) {
			let navBar = window.sessionStorage.getItem('navBar')
			if(navBar) {
				state.navBar = JSON.parse(navBar)
			}else{
				state.navBar = {
					activeIndex: '0',
					List: []
				}
			}
			console.log(state.navBar)
		},
		//生成菜单
		createMenu(state,menus) {
			let list = menus.map(item=>{
				let subMenu = item.child.map(v=>{
					return {
							name: v.name,
							icon: v.icon,
							pathname:v.desc
						}
				})
				
				return {
					name: item.name,
					subActive: '0',
					subMenu:subMenu
				}
			})
			state.navBar.List = list
			window.sessionStorage.setItem('navBar',JSON.stringify(state.navBar))
		}
	},
	actions:{
		
	}
}