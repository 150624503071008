export default {
	data() {
		return {
			preUrl: '',
			multipleSelection: [],
			pageData: {
				current: 1,
				size: 10,
				sizes: [10, 50, 100],
				total: 400
			}
		}
	},
	filters: {
		numToString: function(value) {
			return value.toString();
		},
		tree(item) {
			if (item.level === 0) {
				return item.name
			}
			let str = ''
			for (var i = 0; i < item.level; i++) {
				str += i == 0 ? '|--' : '--'
			}
			return str + ' ' + item.name
		}
	},
	created() {
		this.getList()
	},
	computed: {
		ids: function() {
			return this.multipleSelection.map(item => {
				return item.id
			})
		}
	},
	methods: {
		//处理返回的数据
		getListRes(data) {

		},
		//配置请求地址
		getUrl() {
			return `/admin/${this.preUrl}/index/${this.pageData.current}?limit=${this.pageData.size}`
		},
		//获取列表数据
		getList() {
			if (this.preUrl == '') return
			this.layout.loading = true
			let url = this.getUrl()
			this.axios.get(url, {
					token: true
				})
				.then((res) => {
					this.layout.loading = false
					this.getListRes(res.data.data)
					if(res.data.data.totalCount) {
						this.pageData.total = res.data.data.totalCount
					}
				})
				.catch((err) => {
					this.layout.loading = false
				})
		},
		//新增或修改
		addOrEdit(data, id = 0) {
			let url = id ? `/admin/${this.preUrl}/update/${id}` : `/admin/${this.preUrl}/save`
			let message = id ? '修改' : '添加'
			this.axios.post(url, data, {
					token: true
				})
				.then((res) => {
					this.$message({
						message: message + '成功',
						type: 'success'
					})
					this.getList()
				})
				.catch((err) => {
					this.$message.error({
						message: message + '失败'
					})
				})
		},
		//swich修改记录状态
		changeStatus(data) {
			let message = data.status ? '账户解冻' : '账户冻结'
			this.axios.post(`/admin/${this.preUrl}/updatestatus/${data.id}`, {
					status: data.status
				}, {
					token: true
				})
				.then((res) => {
					this.$message({
						message: message,
						type: 'success'
					})
				})
				.catch((err) => {
					console.log(err)
				})
		},
		//点击修改记录状态
		clickChangeStatus(data) {
			data.status = data.status ? 0 : 1;
			let message = data.status ? '启用成功' : '禁用成功'
			this.axios.post(`/admin/${this.preUrl}/updatestatus/${data.id}`, {
					status: data.status
				}, {
					token: true
				})
				.then((res) => {
					this.$message({
						message: message,
						type: 'success'
					})
				})
				.catch((err) => {
					console.log(err)
				})
		},
		//删除记录
		deleteItem(data) {
			this.$confirm('此操作将永久删除该记录, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.layout.loading = true
				this.axios.post(`/admin/${this.preUrl}/delete/${data.id}`, {}, {
						token: true
					})
					.then((res) => {
						this.$message({
							type: 'success',
							message: '删除成功!'
						});
						this.getList()
					})
					.catch((err) => {
						this.layout.loading = false
					})
			}).catch(() => {
				this.$message({
					type: 'info',
					message: '已取消删除'
				});
			});
		},
		//批量删除
		deleteAll() {
			if (this.multipleSelection.length > 0) {
				this.layout.loading = true
				this.axios.post(`/admin/${this.preUrl}/deleteAll`, {
						ids: this.ids
					}, {
						token: true
					})
					.then((res) => {
						this.$message({
							type: 'success',
							message: '删除成功!'
						});
						this.getList()
					})
					.catch((err) => {
						this.layout.loading = false
					})
			} else {
				this.$message({
					message: '请选择要删除的纪录',
					type: 'warning'
				});
			}
		},
		selectionChange(val) {
			this.multipleSelection = val;
		},
		pageCurrentChange(val) {
			this.pageData.current = val
			this.getList()
		},
		pageSizeChange(val) {
			this.pageData.size = val
			this.getList()
		}
	}
}
